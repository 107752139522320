import React, { useState, useEffect } from "react";
import {
	ThemeProvider,
	CssBaseline,
	Container,
	Box,
	Typography,
	Grid,
	Paper,
	CircularProgress,
	LinearProgress,
} from "@mui/material";
import theme from "./theme";
import {
	authenticateUser,
	fetchProfiles,
	fetchRecommendations,
	fetchRecommendationsWithTopics,
	fetchRecommendationsWithCategory,
} from "./apiService";
import ProfileCard from "./components/ProfileCard";
import ProfileDetail from "./components/ProfileDetail";
import RecommendationPanel from "./components/RecommendationPanel";

function App() {
	const [profiles, setProfiles] = useState([]);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [recommendations, setRecommendations] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [allTopics, setAllTopics] = useState([
		"Teen Romance",
		"Mystery Thriller",
		"Action-Comedy",
	]);
	// const [selectedTopics, setSelectedTopics] = useState([]);
	const [selectedTopics, setSelectedTopics] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");

	const handleCategorySubmission = async (category) => {
		setIsLoading(true);
		try {
			const accessToken = await authenticateUser();
			const recommendationsData = await fetchRecommendationsWithCategory(
				accessToken,
				selectedProfile.profile,
				["category"], // Assuming 'category' is one of the aspects you're filtering by
				category
			);
			setRecommendations(recommendationsData);
			setSelectedCategory(category);
		} catch (error) {
			console.error(
				"Error fetching recommendations based on category:",
				error
			);
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		const loadData = async () => {
			try {
				setIsLoading(true);
				const accessToken = await authenticateUser();
				const profilesData = await fetchProfiles(accessToken);
				setProfiles(profilesData);
				if (profilesData.length > 0) {
					handleSelectProfile(profilesData[0]); // Fetch recommendations for the first profile
				}
			} catch (error) {
				console.error("Failed to fetch data:", error);
			}
			setIsLoading(false);
		};

		loadData();
	}, []);

	const handleSelectProfile = async (profile) => {
		setSelectedProfile(profile);
		setIsLoading(true); // Start loading when a profile is selected
		try {
			const accessToken = await authenticateUser();
			const recommendationsData = await fetchRecommendations(
				accessToken,
				profile.profile,
				["category", "ageGroup", "topic"] // Assuming these are the aspects you're interested in
			);
			setRecommendations(recommendationsData);
		} catch (error) {
			console.error("Failed to fetch recommendations:", error);
		} finally {
			setIsLoading(false); // End loading after fetching recommendations
		}
	};
	const handleTopicSubmission = async (enteredTopics) => {
		setIsLoading(true);
		try {
			const accessToken = await authenticateUser();
			// Use the new or adjusted function for fetching recommendations
			const recommendationsData = await fetchRecommendationsWithTopics(
				accessToken,
				selectedProfile.profile,
				["topic"], // Assuming 'topic' is one of the aspects you're filtering by
				enteredTopics.split(",").map((topic) => topic.trim()) // Processing the enteredTopics string for the request
			);
			setRecommendations(recommendationsData);
		} catch (error) {
			console.error(
				"Error fetching recommendations based on topics:",
				error
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Container maxWidth="xl">
				<Box sx={{ my: 4, textAlign: "center" }}>
					<Typography
						variant="h3"
						component="h1"
						gutterBottom
						sx={{ fontWeight: "bold" }}
					>
						EBUCorePlus Personalized Media Recommendations
					</Typography>
					<Typography
						variant="h5"
						color="textSecondary"
						sx={{ mb: 2 }}
					>
						Driven by Knowledge Graphs & Large Language Models
					</Typography>

					<Grid container spacing={2} alignItems="flex-start">
						{/* Horizontal Profile Selection */}
						<Grid item xs={12} lg={6}>
							<Box
								sx={{
									display: { xs: "block", lg: "flex" },
									overflowX: { lg: "auto" },
									mb: 4,
								}}
							>
								{profiles.length ? (
									profiles.map((profile) => (
										<div
											key={profile.profile}
											onClick={() =>
												handleSelectProfile(profile)
											}
											style={{
												cursor: "pointer",
												minWidth: "160px",
												margin: "8px",
											}}
										>
											<ProfileCard profile={profile} />
										</div>
									))
								) : (
									<CircularProgress />
								)}
							</Box>
							{/* Profile Details */}
							<Paper
								elevation={3}
								sx={{ p: 2, minHeight: "300px" }}
							>
								{selectedProfile ? (
									<ProfileDetail
										selectedProfile={selectedProfile}
										onSubmitTopics={handleTopicSubmission}
										onSubmitCategory={
											handleCategorySubmission
										}
									/>
								) : (
									<Typography>
										Select a profile to see details and
										personalized recommendations.
									</Typography>
								)}
							</Paper>
						</Grid>

						{/* Recommendations */}
						<Grid item xs={12} lg={6}>
							<Paper
								elevation={3}
								sx={{ p: 2, minHeight: "300px" }}
							>
								<Typography variant="h6" gutterBottom>
									Tailored Recommendations for You
								</Typography>
								<Typography variant="subtitle2" gutterBottom>
									Your unique media choices, handpicked by
									cutting-edge AI technology.
								</Typography>
								{isLoading ? (
									<LinearProgress />
								) : (
									<RecommendationPanel
										recommendations={recommendations}
									/>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Box>
			</Container>
			<Box
				sx={{
					mt: 4,
					textAlign: "center",
					fontSize: "12px",
					color: "text.secondary",
				}}
			>
				&copy; {new Date().getFullYear()} Innotrade.com. All rights
				reserved.
			</Box>
		</ThemeProvider>
	);
}

export default App;
