import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#556cd6",
		},
		secondary: {
			light: "#0066ff",
			main: "#0044ff",
			contrastText: "#ffcc00",
		},
		background: {
			default: "#f4f5fd",
		},
	},
	typography: {
		fontFamily: "Arial, sans-serif",
		body1: {
			fontWeight: 500,
		},
		button: {
			fontStyle: "italic",
		},
	},
});

export default theme;
