import axios from "axios";

const authUrl =
	"https://enapso.innotrade.com/auth/realms/ebu-tenant-dev/protocol/openid-connect/token";
const profileUrl =
	"https://enapso.innotrade.com/enapso-dev/view-management/v1/execute-template-by-name";

export const authenticateUser = async () => {
	const params = new URLSearchParams();
	params.append("grant_type", "password");
	params.append("client_id", "enapso-sdk");
	params.append("username", "demo-user");
	params.append("password", "demo-user");

	try {
		const response = await axios.post(authUrl, params, {
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
		});
		return response.data.access_token;
	} catch (error) {
		console.error("Authentication error:", error);
		throw new Error("Failed to authenticate");
	}
};

export const fetchProfiles = async (accessToken) => {
	try {
		const response = await axios.post(
			profileUrl,
			{ templateName: "retrieveProfileInstances" },
			{ headers: { "x-enapso-auth": `${accessToken}` } }
		);
		return response.data.records;
	} catch (error) {
		console.error("Fetching profiles error:", error);
		throw new Error("Failed to fetch profiles");
	}
};
// export const fetchRecommendations = async (accessToken, profileId, basis) => {
// 	let url =
// 		"https://enapso.innotrade.com/enapso-dev/view-management/v1/execute-template-by-name"; // Default URL
// 	let requestBody = {}; // Default request body

// 	switch (basis) {
// 		case "categories":
// 			requestBody = {
// 				templateName: "retrieveMovieInstancesBasedOnProfilePreferences",
// 				variables: { profiles: [profileId] },
// 			};
// 			break;
// 		case "ageGroup":
// 			requestBody = {
// 				templateName: "retrieveMovieInstancesBasedOnProfileAgeGroup",
// 				variables: { profiles: [profileId] },
// 			};
// 			break;
// 		case "topics":
// 			url =
// 				"https://enapso.innotrade.com/enapso-dev/media-recommendation/v1/recommend-movies-based-on-topics"; // Different URL for topics
// 			requestBody = {
// 				profileIRI: profileId,
// 			};
// 			break;
// 		default:
// 			throw new Error("Invalid recommendation basis");
// 	}

// 	try {
// 		const response = await axios.post(url, requestBody, {
// 			headers: {
// 				"x-enapso-auth": `${accessToken}`,
// 				"Content-Type": "application/json", // Ensure correct content type is set, especially for JSON body
// 			},
// 		});
// 		return response.data.records || response.data; // Adjust based on the response structure for topics
// 	} catch (error) {
// 		console.error(`Fetching recommendations error (${basis}):`, error);
// 		throw new Error(`Failed to fetch recommendations based on ${basis}`);
// 	}
// };
// Within apiService.js or wherever your API service functions are defined
export const fetchRecommendations = async (accessToken, profileId, aspects) => {
	let url =
		"https://enapso.innotrade.com/enapso-dev/media-recommendation/v1/recommend-movies-based-on-multiple-aspects";
	let requestBody = {
		profileIRI: profileId,
		aspects: aspects,
	};

	try {
		const response = await axios.post(url, requestBody, {
			headers: {
				"x-enapso-auth": `${accessToken}`,
				"Content-Type": "application/json",
			},
		});
		return response.data.records || response.data; // Adjust according to the actual response structure
	} catch (error) {
		console.error(`Fetching recommendations error:`, error);
		throw new Error(`Failed to fetch recommendations`);
	}
};

export const fetchRecommendationsWithTopics = async (
	accessToken,
	profileId,
	aspects,
	topics
) => {
	let url =
		"https://enapso.innotrade.com/enapso-dev/media-recommendation/v1/recommend-movies-based-on-multiple-aspects";
	let requestBody = {
		profileIRI: profileId,
		aspects: aspects,
	};

	// Add topics to the requestBody if topics are provided
	if (topics && topics.length > 0) {
		requestBody.topics = topics;
	}

	try {
		const response = await axios.post(url, requestBody, {
			headers: {
				"x-enapso-auth": `${accessToken}`,
				"Content-Type": "application/json",
			},
		});
		return response.data.records || response.data; // Adjust according to the actual response structure
	} catch (error) {
		console.error(`Fetching recommendations error:`, error);
		throw new Error(`Failed to fetch recommendations`);
	}
};

export const fetchRecommendationsWithCategory = async (
	accessToken,
	profileId,
	aspects,
	category
) => {
	let url =
		"https://enapso.innotrade.com/enapso-dev/media-recommendation/v1/recommend-movies-based-on-multiple-aspects";
	let requestBody = {
		profileIRI: profileId,
		aspects: aspects,
	};
	// Add category to the requestBody if category is provided
	if (category) {
		requestBody.categories = [category];
	}
	try {
		const response = await axios.post(url, requestBody, {
			headers: {
				"x-enapso-auth": `${accessToken}`,
				"Content-Type": "application/json",
			},
		});
		return response.data.records || response.data; // Adjust according to the actual response structure
	} catch (error) {
		console.error(`Fetching recommendations error:`, error);
		throw new Error(`Failed to fetch recommendations`);
	}
};
