import React, { useState } from "react";
import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	Grid,
	Box,
	Collapse,
	IconButton,
	Divider,
	Chip,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const RecommendationPanel = ({ recommendations }) => {
	const [expandedIndex, setExpandedIndex] = useState(null);

	const handleExpandClick = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	return (
		<Grid container spacing={2}>
			{recommendations.map((recommendation, index) => (
				<Grid item xs={12} sm={6} md={4} key={index}>
					<Card sx={{ maxWidth: 345, mb: 2 }}>
						<CardMedia
							component="img"
							height="200"
							image={recommendation.src}
							alt={recommendation.movieTitle}
						/>
						<CardContent>
							<Typography
								gutterBottom
								variant="h6"
								component="div"
							>
								{recommendation.movieTitle}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{recommendation.description}
							</Typography>
							<Box sx={{ mt: 2 }}>
								<Chip
									label={`Start: ${new Date(
										recommendation.startTime
									).toLocaleString()}`}
									size="small"
									sx={{ mr: 1 }}
								/>
								<Chip
									label={`End: ${new Date(
										recommendation.endTime
									).toLocaleString()}`}
									size="small"
								/>
							</Box>
							<Typography variant="subtitle2" sx={{ mt: 1 }}>
								Channel: {recommendation.channel}
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									mt: 1,
								}}
							>
								<Typography variant="subtitle2" sx={{ mr: 1 }}>
									Erklärung
								</Typography>
								<IconButton
									onClick={() => handleExpandClick(index)}
									aria-expanded={expandedIndex === index}
									aria-label="show more"
								>
									{expandedIndex === index ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)}
								</IconButton>
							</Box>
							{/* <IconButton
								onClick={() => handleExpandClick(index)}
								aria-expanded={expandedIndex === index}
								aria-label="show more"
							>
								{expandedIndex === index ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
							</IconButton> */}
						</CardContent>
						<Collapse
							in={expandedIndex === index}
							timeout="auto"
							unmountOnExit
						>
							<CardContent>
								<Divider sx={{ mb: 2 }} />
								{recommendation.categoryExplanations && (
									<>
										<Typography
											variant="subtitle1"
											sx={{ mb: 1 }}
										>
											Category Explanations:
										</Typography>
										{recommendation.categoryExplanations.map(
											(explanation, idx) => (
												<Box key={idx} sx={{ mb: 2 }}>
													<Typography variant="subtitle2">
														Category:{" "}
														{explanation.category}
													</Typography>
													<Typography variant="body2">
														Relevance Score:{" "}
														{
															explanation.relevanceScore
														}
													</Typography>
													<Typography variant="body2">
														Explanation:{" "}
														{
															explanation
																.explanation.en
														}
													</Typography>
												</Box>
											)
										)}
									</>
								)}
								{recommendation.ageGroupExplanation && (
									<>
										<Typography
											variant="subtitle1"
											sx={{ mb: 1 }}
										>
											Age Group Explanation:
										</Typography>
										{recommendation.ageGroupExplanation.map(
											(explanation, idx) => (
												<Box key={idx} sx={{ mb: 2 }}>
													<Typography variant="subtitle2">
														Age Group:{" "}
														{explanation.ageGroup}
													</Typography>
													<Typography variant="body2">
														Relevance Score:{" "}
														{
															explanation.relevanceScore
														}
													</Typography>
													<Typography variant="body2">
														Explanation:{" "}
														{
															explanation
																.explanation.en
														}
													</Typography>
												</Box>
											)
										)}
									</>
								)}
							</CardContent>
						</Collapse>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};

export default RecommendationPanel;
