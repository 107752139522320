// ProfileCard component
import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";

const ProfileCard = ({ profile }) => (
	<Card sx={{ maxWidth: 160, m: 1, boxShadow: 3 }}>
		<CardMedia
			component="img"
			height="140"
			image={profile.profileURL}
			alt={`${profile.givenname} ${profile.surname}`}
			sx={{ width: "100%", objectFit: "cover" }}
		/>
		<CardContent>
			<Typography gutterBottom variant="body2" component="div" noWrap>
				{`${profile.givenname} ${profile.surname}`}
			</Typography>
		</CardContent>
	</Card>
);

export default ProfileCard;
