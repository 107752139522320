import React, { useState } from "react";
import {
	Card,
	CardContent,
	Typography,
	Divider,
	TextField,
	Button,
	Chip,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	Box,
} from "@mui/material";

const ProfileDetail = ({
	selectedProfile,
	onSubmitTopics,
	onSubmitCategory,
}) => {
	const [enteredTopic, setEnteredTopic] = useState("");
	const [selectedCategory, setSelectedCategory] = useState("");

	const categories = [
		"Musik",
		"Liebe & Sexualität",
		"Comedy & Satire",
		"Freizeit & Hobby",
		"Krimi",
		"Politik",
	];

	if (
		!selectedProfile ||
		!selectedProfile.preferences ||
		!selectedProfile.topic
	) {
		return <Typography>Loading profile details...</Typography>;
	}

	const handleSubmitTopics = () => {
		onSubmitTopics(enteredTopic);
		// setEnteredTopic("");
	};

	const handleSubmitCategory = () => {
		onSubmitCategory(selectedCategory);
		// setSelectedCategory("");
	};

	return (
		<Card sx={{ my: 2, boxShadow: 4 }}>
			<CardContent>
				<Typography
					gutterBottom
					variant="h4"
					component="div"
					sx={{ mb: 2 }}
				>
					{`${selectedProfile.givenname} ${selectedProfile.surname}`}
				</Typography>
				<Divider variant="middle" sx={{ mb: 2 }} />

				{/* Gender and Age */}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={6}>
						<Typography
							variant="subtitle1"
							color="textSecondary"
							component="p"
						>
							Gender: {selectedProfile.gender}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="subtitle1"
							color="textSecondary"
							component="p"
						>
							Age: {selectedProfile.age}
						</Typography>
					</Grid>
				</Grid>

				{/* Favorite Categories */}
				<Typography
					variant="h6"
					color="textPrimary"
					component="p"
					sx={{ mt: 2 }}
				>
					Favorite Categories
				</Typography>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					{selectedProfile.preferences.map((preference, index) => (
						<Grid item xs={12} sm={6} key={index}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography variant="body1" sx={{ mr: 1 }}>
									{preference.category}:
								</Typography>
								<Typography variant="body1" color="primary">
									{preference.weighting}
								</Typography>
							</Box>
						</Grid>
					))}
				</Grid>

				{/* Topics */}
				<Typography
					variant="h6"
					color="textPrimary"
					component="p"
					sx={{ mt: 2 }}
				>
					Topics
				</Typography>
				<Box sx={{ mb: 2 }}>
					{selectedProfile.topic.map((topic, index) => (
						<Chip key={index} label={topic} sx={{ mr: 1, mb: 1 }} />
					))}
				</Box>

				{/* Explicit Topic Search */}
				<Typography
					variant="h6"
					color="textPrimary"
					component="p"
					sx={{ mt: 2 }}
				>
					Explicit Topic Search
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
					<TextField
						variant="outlined"
						label="Search Topic"
						placeholder="Enter a topic to search"
						value={enteredTopic}
						onChange={(e) => setEnteredTopic(e.target.value)}
						sx={{ flexGrow: 1, mr: 2 }}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmitTopics}
					>
						Search by Topic
					</Button>
				</Box>

				{/* Explicit Category Search */}
				<Typography
					variant="h6"
					color="textPrimary"
					component="p"
					sx={{ mt: 2 }}
				>
					Explicit Category Search
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<FormControl variant="outlined" sx={{ flexGrow: 1, mr: 2 }}>
						<InputLabel id="category-select-label">
							Select Category
						</InputLabel>
						<Select
							labelId="category-select-label"
							value={selectedCategory}
							onChange={(e) =>
								setSelectedCategory(e.target.value)
							}
							label="Select Category"
						>
							<MenuItem value="">
								<em>Select a category</em>
							</MenuItem>
							{categories.map((category, index) => (
								<MenuItem key={index} value={category}>
									{category}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmitCategory}
						disabled={!selectedCategory}
					>
						Search by Category
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ProfileDetail;
